import { Box, styled } from "@grupoboticario/flora-react";

export const MenuItemSidemenu = styled(Box, {
  backgroundColor: "$backgroundPrimary",
  ul: {
    listStyleType: "none",
    marginBlock: "$0",
    paddingInline: "$0",
    backgroundColor: "$backgroundPrimary",
    color: "$nonInteractiveAuxiliar",
    "&:before, &:after": {
      backgroundColor: "transparent",
    },
    "&>li": {
      "&>button": {
        borderBottom: "solid 1px $nonInteractiveAltAuxiliar",
      },
    },
    li: {
      marginBottom: "0 important",
      button: {
        fontSize: "$bodySmall",
        fontWeight: "$medium",
        "&[data-state=open]": {
          color: "#626CC3",
        },
      },
      "a, button": {
        '&[data-level="1"]': {
          padding: "$5 $4",
        },
        '&[data-level="2"]': {
          padding: "$5 $8",
        },
      },
      a: {
        borderBottom: "solid 1px $nonInteractiveAltAuxiliar",
        textDecoration: "none",
        padding: "$5 $4",
        paddingLeft: "$2",
        ":before": {
          width: "$0",
        },
        height: "56px !important",
        fontSize: "$bodySmall",
        fontWeight: "$medium",
        "&[data-control][data-active=true]": {
          color: "#626CC3",
          "&:before": {
            display: "none",
          },
        },
      },
    },
    ".focused": {
      color: "#626CC3",
    },
  },
});
