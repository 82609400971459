import { useEffect } from "react";
import { useInvalidateNotificationQuery } from "./useInvalidateNotificationQuery";
import { socket } from "../api/socket";
import { useReadNotificationItem } from "./useReadNotificationItem";

import type { Notification } from "../types";

export function useReactQuerySubscription() {
  const { mutate: markOneAsRead } = useReadNotificationItem();
  const invalidateNotificationQuery = useInvalidateNotificationQuery();

  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleEvent = (event: string, payload: Notification) => {
      /**
       * Somente notificações persistentes geram registros na consulta de notificações
       * Caso seja um tipo PASSTHROUGH podemos simplesmente ignorar, pois não são eventos
       * em que o usuário tenha que realizar alguma ação (ex: status de mensagem)
       */
      if (payload.type !== "PERSIST") {
        return;
      }

      /**
       * Caso o usuário esteja navegando na tela que é responsável pelo processamento do
       * evento recebido, marcamos a notificação como lida automaticamente, para evitar de
       * gerar notificações duplicadas (na tela e na central de notificações)
       */
      if (socket.hasListeners(event)) {
        return markOneAsRead(
          { ...payload },
          {
            onSuccess: () => {
              invalidateNotificationQuery();
            },
          },
        );
      }

      /**
       * Cenário onde a tela ativa não é responsável pelo processamento da notificação recebida
       * Precisamos gerar um aviso de notificação para que ele fique ciente do evento
       */
      invalidateNotificationQuery();
    };
    socket.onAny(handleEvent);

    return () => {
      socket.offAny(handleEvent);
    };
  }, [invalidateNotificationQuery, markOneAsRead]);
}
