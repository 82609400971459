import {
  HandshakeIcon,
  FinanceModeIcon,
  DollarSignArrowIcon,
} from "@grupoboticario/flora-react-icons";

import { type MenuOptions } from "./types";

export const options: MenuOptions = [
  {
    label: "Inícios",
    icon: <HandshakeIcon />,
    url: "/inicios/",
    options: [
      {
        label: "Segmentação de base - IRs",
        url: "/inicios/mailing/atendente/base-de-irs",
      },
      {
        label: "Distribuição de base - IRs",
        url: "/inicios/mailing/control/distribuicao-irs",
      },
      {
        label: "Atendimento",
        url: "/inicios/atendimento",
      },
      {
        label: "Centrais de serviço",
        url: "/inicios/centrais-de-servico",
      },
      {
        label: "Histórico de alteracões",
        url: "/inicios/logs",
      },
    ],
  },
  {
    label: "Ativação",
    icon: <DollarSignArrowIcon />,
    url: "/ativacao/",
    options: [
      {
        label: "Atendimento",
        url: "/ativacao/atendimento",
      },
      {
        label: "Segmentação de base",
        url: "/ativacao/segmentacao",
      },
    ],
  },
  {
    label: "Resultados",
    icon: <FinanceModeIcon />,
    url: "/dashboards/",
    options: [
      {
        label: "Central de Ativação",
        options: [
          {
            label: "Receita",
            url: "/dashboards/receita",
          },
          {
            label: "Perfil de Base",
            url: "/dashboards/perfil-de-base",
          },
          {
            label: "Relacionamento",
            url: "/dashboards/relacionamento",
          },
        ],
      },
      {
        label: "Ajuda e documentação",
        options: [
          {
            label: "Memória de cálculo",
            url: "/dashboards/memoria-de-calculo",
          },
        ],
      },
    ],
  },
];
