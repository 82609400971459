import { ReactNode } from "react";
import { SubItem } from "./menu.styles";

interface MenuSubItemProps {
  children: ReactNode;
}

export function MenuSubItem({ children }: MenuSubItemProps) {
  return <SubItem className="subItems">{children}</SubItem>;
}
