import { useContext, useState } from "react";

import { Flex, Box } from "@grupoboticario/flora-react";
import { ThreeLinesHorizontal1Icon } from "@grupoboticario/flora-react-icons";

import { MenuIconButton } from "../menu-icon-button";
import { VdiLogo } from "../vdi-logo";
import { MenuItem } from "./menu-item";
import { options } from "../menu-options";
import { MenuContext } from "../menu";

export function MenuMobile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { checkUrl } = useContext(MenuContext);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Flex direction="row" gap="$0" align="center">
        <MenuIconButton
          role="button"
          aria-label="Abrir menu"
          onClick={handleToggleMenu}
        >
          <ThreeLinesHorizontal1Icon />
        </MenuIconButton>
        <VdiLogo />
      </Flex>
      {isMenuOpen && (
        <Box
          css={{
            position: "fixed",
            top: 64,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "$backgroundPrimary",
            transition: "left 1s ease 0s",
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.label}
              icon={option.icon}
              text={option.label}
              options={option.options}
              url={option.url}
              isActive={checkUrl(option.url)}
              onMenuItemClick={handleToggleMenu}
            />
          ))}
        </Box>
      )}
    </>
  );
}
