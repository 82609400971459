import { useContext } from "react";
import { navigateToUrl } from "single-spa";

import { ChatBubbleIcon } from "@grupoboticario/flora-react-icons";
import { type UserData } from "@vdi/auth-service";
import {
  SideMenu,
  SideMenuHeader,
  SideMenuItem,
  SideMenuList,
} from "@grupoboticario/flora-shared-react-side-menu";

import { configCatUniqueUser } from "@shared/configs";
import { ConversationsMenuFeatureFlag } from "@shared/feature-flags";

import { Menu, MenuContext, menuType, useSendClickMenuEvent } from "../menu";
import { VdiLogo } from "../vdi-logo";
import { options } from "../menu-options";

export function MenuDesktop({ user }: { user: UserData }) {
  const { activeMenuItem, checkUrl } = useContext(MenuContext);

  const triggerMenuEvent = useSendClickMenuEvent();

  const navigate = (url) => {
    triggerMenuEvent({ url, menu: menuType.SUBMENU });
    activeMenuItem(url);
    navigateToUrl(url);
  };

  return (
    <>
      <VdiLogo />
      <Menu.Root>
        {options.map((item) => (
          <Menu.Item key={item.label} isActive={checkUrl(item.url)}>
            <Menu.Icon icon={item.icon} />
            <Menu.Label label={item.label} />
            {item.options && (
              <Menu.SubItem>
                <SideMenu
                  className="subMenu"
                  css={{ backgroundColor: "$backgroundPrimary" }}
                >
                  <SideMenuHeader
                    css={{
                      padding: "$5 $4",
                      lineHeight: "$shorter",
                      fontWeight: "$medium",
                      backgroundColor: "$backgroundPrimary",
                      color: "$nonInteractivePredominant",
                      margin: "0",
                      borderBottom: "1px solid $nonInteractiveOutline",
                    }}
                  >
                    {item.label}
                  </SideMenuHeader>
                  <SideMenuList>
                    {item.options.map((subItem) =>
                      subItem.options ? (
                        <SideMenuItem key={subItem.label} label={subItem.label}>
                          {subItem.options.map((item) => (
                            <SideMenuItem
                              key={item.label}
                              label={item.label}
                              onClick={() => navigate(item.url)}
                              isActive={checkUrl(item.url)}
                            />
                          ))}
                        </SideMenuItem>
                      ) : (
                        <SideMenuItem
                          key={subItem.label}
                          label={subItem.label}
                          onClick={() => navigate(subItem.url)}
                          isActive={checkUrl(subItem.url)}
                        />
                      ),
                    )}
                  </SideMenuList>
                </SideMenu>
              </Menu.SubItem>
            )}
          </Menu.Item>
        ))}
        <ConversationsMenuFeatureFlag user={configCatUniqueUser(user)}>
          <Menu.Item
            isActive={checkUrl("/chat")}
            onClick={() => {
              navigate("/chat");
            }}
          >
            <Menu.Icon icon={<ChatBubbleIcon />} />
            <Menu.Label label="Conversas" />
          </Menu.Item>
        </ConversationsMenuFeatureFlag>
      </Menu.Root>
    </>
  );
}
