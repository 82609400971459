import { useEvents } from "@shared/events";

const urlToLabelObject = {
  "/dashboards/": "resultados",
  "/dashboards/visao-geral": "resultados_visao-geral",
  "/dashboards/atendimento": "resultados_atendimento",
  "/inicios/": "inicios",
  "/inicios/atendimento": "inicios_atendimento",
  "/inicios/centrais-de-servico": "inicios_centrais-de-servico",
  "/inicios/logs": "inicios_logs",
  "/inicios/mailing/atendente/base-de-irs": "inicios_mailing",
  "/ativacao/": "ativacao",
  "/ativacao/atendimento": "ativacao_atendimento",
  "/ativacao/segmentacao": "ativacao_segmentacao",
};

const useSendClickMenuEvent = () => {
  const { clickMenu } = useEvents();

  return ({ url, menu }) => {
    const hasItemOnObject = urlToLabelObject[url];
    if (!hasItemOnObject) {
      return;
    }

    clickMenu(urlToLabelObject[url], menu);
  };
};

export { useSendClickMenuEvent };
