import { styled } from "@grupoboticario/flora-react";

const NotificationListWrapper = styled("ul", {
  maxHeight: "calc(100vh - 140px)",
  overflowY: "scroll",
  color: "#000",
  background:
    "linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,radial-gradient(farthest-side at 50% 0, rgba(204,204,204, 0.5), rgba(0,0,0,0)),radial-gradient(farthest-side at 50% 100%, rgba(204,204,204, 0.5), rgba(0,0,0,0)) 0 100%",
  backgroundColor: "#ffffff",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "local, local, scroll, scroll",
  backgroundSize: "100% 60px, 100% 60px, 100% 20px, 100% 20px",
  margin: 0,
  padding: 0,
  overscrollBehavior: "contain",
  "&:last-child": {
    borderRadius: "0 0 7px 7px",
  },
});

export function NotificationList({ children }) {
  return <NotificationListWrapper>{children}</NotificationListWrapper>;
}
