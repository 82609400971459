import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

export function useInvalidateNotificationQuery() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["notification"],
    });
  }, [queryClient]);
}
