import { Text } from "@grupoboticario/flora-react";

interface MenuItemLabelProps {
  label: string;
}

export function MenuItemLabel({ label }: MenuItemLabelProps) {
  return (
    <Text weight={"medium"} size="auxiliarMini">
      {label}
    </Text>
  );
}
