import { Box, styled } from "@grupoboticario/flora-react";

export const NavItems = styled(Box, {
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  gap: "$6",
});

export const Item = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "$nonInteractiveAltPredominant",
  gap: "$1",
  padding: "$2 $1",
  cursor: "pointer",
  border: "solid 3px transparent",
  borderWidth: "0 3px",
  "&:hover": {
    backgroundColor: "$backgroundAltTertiary",
  },
  "&:hover .subItems": {
    left: "$20",
  },
  "&.active": {
    backgroundColor: "$backgroundAltTertiary",
    borderLeftColor: "$nonInteractiveAltPredominant",
  },
  transition: "background-color 0.2s",
});

export const SubItem = styled(Box, {
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  zIndex: "-1",
  position: "absolute",
  left: "-15rem",
  top: "$0",
  width: "15rem",
  height: "100%",
  backgroundColor: "$backgroundPrimary",
  boxShadow: "4px 0px 8px -4px #00000052",
  transition: "all .5s ease",
  cursor: "auto",
  color: "$nonInteractivePredominant",
  "&.subItems": {
    ul: {
      listStyleType: "none",
      marginBlock: "$0",
      paddingInline: "$0",
      backgroundColor: "$backgroundPrimary",
      color: "$nonInteractivePredominant",
      "&:before, &:after": {
        backgroundColor: "transparent",
      },
      ".focused": {
        color: "$actionableDefault",
      },
      a: {
        textDecoration: "none",
        padding: "$5 $10",
        border: "solid $nonInteractiveOutline",
        borderWidth: "0 0 1px 0",
        ":before": {
          width: "$0",
        },
        "&[data-control][data-level='2']": {
          color: "$nonInteractivePredominant",
        },
        "&[data-control][data-active=true]": {
          color: "$actionableDefault",
          backgroundColor: "$backgroundPrimary",
          "&:before": {
            display: "none",
          },
          "&:hover": {
            backgroundColor: "$backgroundSecondary",
          },
        },
      },
    },
    ".subMenu": {
      "> ul": {
        "> li": {
          "[data-control][data-level='1']": {
            border: "solid $nonInteractiveOutline",
            borderWidth: "0 0 1px 0",
            padding: "$2 $4",
          },
          "&:has([data-active=true])": {
            button: {
              color: "$actionableDefault",
            },
            "[data-accordion-arrow] > svg": {
              fill: "$actionableDefault",
            },
          },
        },
      },
    },
  },
  "&.subItems ul li button, &.subItems ul a": {
    fontSize: "$bodySmall",
    fontWeight: "$medium",
    lineHeight: "$bodySmallShort",
  },
});
