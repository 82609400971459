import { authService } from "@vdi/auth-service";
import { io } from "socket.io-client";

const socket = io(`${process.env.API_BASE_URL}`, {
  autoConnect: false,
  auth: (sendCredentialsToConnect) => {
    authService.getTokenOrRedirect().then((auth) => {
      sendCredentialsToConnect({ authorization: `Bearer ${auth.token}` });
    });
  },
  path: "/socket.io",
  transports: ["websocket"],
});

export { socket };
