import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UserData } from "@vdi/auth-service";
import { configCatUniqueUser } from "@shared/configs";

import { queryClient } from "./api/queryClient";
import { ConversationsMenuFeatureFlag } from "@shared/feature-flags";
import { NotificationMenuWrapper } from "./NotificationMenuWrapper";

export function NotificationMenu({ user }: { user: UserData }) {
  return (
    <BrowserRouter basename="/">
      <QueryClientProvider client={queryClient}>
        <ConversationsMenuFeatureFlag user={configCatUniqueUser(user)}>
          <NotificationMenuWrapper />
        </ConversationsMenuFeatureFlag>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}
