import React from "react";
import { Image } from "@grupoboticario/flora-react";
import vdilogo from "./vdi-logo.svg";

export const VdiLogo: React.FC = () => {
  return (
    <Image
      css={{ maxWidth: "none", width: "$20", height: "39px" }}
      alt="Logo da VDI - Venda Direta Interativa"
      src={vdilogo}
    />
  );
};
