import { Button, styled } from "@grupoboticario/flora-react";

export const NotificationItem = styled("li", {
  display: "flex",
  padding: "16px 40px 16px 24px",
  margin: 0,
  position: "relative",
  color: "$actionNavigationStandardHighlight",
  textDecoration: "none",
  fontSize: "14px",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
  textWrap: "pretty",
  transition: "all 200ms ease-in-out",
  cursor: "pointer",
  lineHeight: "1.2em",
  "&:hover": {
    paddingLeft: "28px",
    textDecoration: "underline",
  },
  "&:not(:first-of-type)": {
    borderTop: "1px solid #ccc",
  },
  "&:not(:last-of-type)": {
    boxShadow: "inset 0 -1px 0 white",
  },
  variants: {
    hasNotification: {
      true: {
        "&::before": {
          transition: "all 200ms ease-in-out",
          content: "''",
          display: "block",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: "$statusErrorNonInteractiveEmphasis",
          position: "absolute",
          left: "8px",
          top: "20px",
        },
      },
      false: {
        backgroundColor: "#f2f3f4",
        color: "#999",
        "&:hover": {
          color: "#7A8CCB",
        },
      },
    },
  },
});

export const NotificationIcon = styled(Button, {
  width: "100% !important",
  color: "$backgroundPrimary !important",
  padding: "11px",
  "& svg": {
    width: "24px",
    height: "24px",
  },
  variants: {
    hasNotification: {
      true: {
        "&::after": {
          content: "''",
          display: "block",
          width: "14px",
          height: "14px",
          borderRadius: "50%",
          backgroundColor: "$statusErrorNonInteractiveEmphasis",
          position: "absolute",
          right: "24px",
        },
      },
    },
  },
});
