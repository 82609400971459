import { MenuItem } from "./menu-item";
import { MenuItemIcon } from "./menu-item-icon";
import { MenuItemLabel } from "./menu-item-label";
import { MenuRoot } from "./menu-root";
import { MenuSubItem } from "./menu-sub-item";

export { MenuProvider, MenuContext } from "./menu-context";
export * from "./menu.types";
export * from "./use-send-click-menu-event";

export const Menu = {
  Root: MenuRoot,
  Item: MenuItem,
  Icon: MenuItemIcon,
  Label: MenuItemLabel,
  SubItem: MenuSubItem,
};
