import { ReactNode } from "react";
import { useFeatureFlag, type User } from "configcat-react";

export function ConversationsMenuFeatureFlag({
  user,
  children,
}: {
  user: User;
  children: ReactNode;
}) {
  const { value: notificationListValue, loading: notificationListLoading } =
    useFeatureFlag("notificationList", false, user);

  if (notificationListLoading || !notificationListValue) {
    return null;
  }

  return <>{children}</>;
}
