import { authService } from "@vdi/auth-service";

export const mutateReadAllNotificationsFromAGroup = async ({
  groupId,
}: {
  groupId: string;
}): Promise<void> => {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/v1/notifications?groupId=${groupId}`;

  const resp = await fetch(url, {
    method: "PATCH",
    body: JSON.stringify({ read: true }),
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
  });

  if (!resp.ok) {
    throw new Error("Network response was not ok");
  }
};
