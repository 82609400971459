import { ReactNode } from "react";
import { Item } from "./menu.styles";

interface MenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  isActive: boolean;
}

export function MenuItem({ children, isActive, ...rest }: MenuItemProps) {
  return (
    <Item className={`box-item ${isActive ? "active" : ""}`} {...rest}>
      {children}
    </Item>
  );
}
