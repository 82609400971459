import { IEventTracker } from "@vdi/event-tracker";
import { Event } from "./events.types";

interface NavbarEvents {
  clickMenu: (type: string, item: string) => void;
}

const createNavbarEvents = (
  tracker: IEventTracker<Event, string>,
): NavbarEvents => ({
  clickMenu: function (type: string, item: string) {
    tracker.track({
      event: "analytics-event",
      event_parameters: {
        event_name: "interaction_atendimento",
        cd_interaction_detail: `${item}:${type}`,
      },
    });
  },
});

export { createNavbarEvents, NavbarEvents };
