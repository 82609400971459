import { GtmTracker, IEventTracker } from "@vdi/event-tracker";

import { createNavbarEvents, NavbarEvents } from "./events.navbar";

import { Event } from "./events.types";

type UseEvents = NavbarEvents;

const useEvents = (): UseEvents => ({
  ...createNavbarEvents(GtmTracker as unknown as IEventTracker<Event, string>),
});

export { useEvents, UseEvents };
