import { useMutation } from "@tanstack/react-query";
import { mutateReadAllNotificationsFromAGroup } from "../api/mutate/mutateReadAllNotificationsFromAGroup";
import { queryClient } from "../api/queryClient";

export function useReadAllNotificationsFromAGroup() {
  return useMutation(
    {
      mutationKey: ["notification", "read"],
      mutationFn: ({ groupId }: { groupId: string }) =>
        mutateReadAllNotificationsFromAGroup({ groupId }),
      onSuccess() {
        queryClient.refetchQueries({ queryKey: ["notification"] });
      },
    },
    queryClient,
  );
}
