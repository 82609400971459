import { Card } from "@grupoboticario/flora-react";

export function NotificationCard({ children }) {
  return (
    <Card
      variant="active"
      css={{
        position: "absolute",
        bottom: "16px",
        left: "96px",
        width: "320px",
        maxHeight: "calc(100vh - 32px)",
        border: "1px solid #ccc",
        cursor: "default",
        padding: 0,
        zIndex: 888,
        "&::after": {
          content: "",
          width: "16px",
          height: "16px",
          backgroundColor: "white",
          position: "absolute",
          left: "-8px",
          bottom: "84px",
          transform: "rotate(45deg)",
          zIndex: "-1",
          border: "1px solid rgb(204, 204, 204)",
          borderRadius: "2px",
        },
      }}
    >
      {children}
    </Card>
  );
}
