import { ReactNode } from "react";
import { NavItems } from "./menu.styles";

interface MenuRootProps {
  children: ReactNode;
}

export function MenuRoot({ children }: MenuRootProps) {
  return <NavItems>{children}</NavItems>;
}
