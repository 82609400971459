import { useQuery } from "@tanstack/react-query";
import { fetchNotificationList } from "../api/fetch/fetchNotificationList";

export const useNotificationList = ({ enabled }) => {
  return useQuery({
    enabled,
    queryKey: ["notification", "list"],
    queryFn: ({ signal }) => fetchNotificationList(signal),
  });
};
