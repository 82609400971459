import { Flex } from "@grupoboticario/flora-react";

export function NotificationCardHeader({ children }) {
  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      css={{
        padding: "8px 8px 8px 16px",
        color: "#444",
        borderBottom: "1px solid #ccc",
      }}
    >
      {children}
    </Flex>
  );
}
