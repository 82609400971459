import { authService } from "@vdi/auth-service";

export const fetchNotificationsCount = async (
  signal,
): Promise<{
  count: number;
}> => {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/v1/notifications/unread`;

  const resp = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

  if (!resp.ok) {
    throw new Error("Network response was not ok");
  }

  return resp.json();
};
