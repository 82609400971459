import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Spacer,
  useIsMobile,
} from "@grupoboticario/flora-react";
import { useEffect } from "react";
import useKeyPress from "react-use/lib/useKeyPress";
import type { UserMenuProps } from "../user-menu";
import { authService } from "@vdi/auth-service";

type UserMenuModalProps = {
  open: boolean;
  onClose: () => void;
} & UserMenuProps;

const UserMenuModal = ({
  open,
  onClose,
  user,
}: UserMenuModalProps): JSX.Element => {
  const isPressed = useKeyPress("Escape");
  const isMobile = useIsMobile();

  useEffect(() => {
    const [isEscPressed] = isPressed;

    if (isEscPressed) {
      onClose();
    }
  }, [isPressed, onClose]);

  function handleLogout(): void {
    authService.signOut();
  }

  return (
    <Modal open={open}>
      <ModalContent
        size="small"
        aria-label="Menu"
        css={{ borderRadius: "$small" }}
        isFull={isMobile}
      >
        <ModalBody css={{ lineHeight: "$base" }}>
          <Flex align="center" css={{ paddingBottom: "$4" }}>
            <Spacer />
            <ModalCloseButton aria-label="Fechar menu" onClick={onClose} />
          </Flex>
          <ul>
            <li>Nome: {user.name}</li>
            <li>GeraID: {user.geraId}</li>
            <li>Email: {user.email}</li>
            <li>Criado em: {user.createdAt}</li>
          </ul>
          <Button css={{ marginTop: "$6" }} isFull onClick={handleLogout}>
            Desconectar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { UserMenuModal, UserMenuModalProps };
