import { Flex } from "@grupoboticario/flora-react";

export function NotificationCardFooter({ children }) {
  return (
    <Flex
      as="footer"
      align="center"
      justify="end"
      css={{
        padding: "8px",
        marginTop: "auto",
        borderTop: "1px solid #ccc",
      }}
    >
      {children}
    </Flex>
  );
}
