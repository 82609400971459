import { UserMenuModal } from "./user-menu-modal";
import { PersonCircleIcon } from "@grupoboticario/flora-react-icons";
import { useState } from "react";
import { MenuIconButton } from "../menu-icon-button";

type UserMenuProps = {
  user: {
    createdAt?: string;
    email?: string;
    geraId?: string;
    name?: string;
    updatedAt?: string;
  };
};

const UserMenu = ({ user }: UserMenuProps): JSX.Element => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const handleOpenMenu = (): void => {
    setMenuIsOpen(true);
  };

  const handleCloseMenu = (): void => {
    setMenuIsOpen(false);
  };

  return (
    <>
      <MenuIconButton
        as="button"
        onClick={handleOpenMenu}
        aria-label="Abrir menu"
        data-testid="UserMenu"
      >
        <PersonCircleIcon />
      </MenuIconButton>
      <UserMenuModal open={menuIsOpen} onClose={handleCloseMenu} user={user} />
    </>
  );
};

export { UserMenu, UserMenuProps };
