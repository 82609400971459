import { useEffect } from "react";
import { socket } from "../api/socket";

/**
 * Assina o recebimento de notificações para processamento na tela
 * @param eventName Tipo da notificação
 * @param handler Função para processamento
 */
export function useNotificationListener<T>(
  eventName: string,
  handler: (payload: T) => void | Promise<void>,
) {
  useEffect(() => {
    socket.on(eventName, handler);

    return () => {
      socket.off(eventName, handler);
    };
  }, [eventName, handler]);
}
