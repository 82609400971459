import { Box, styled } from "@grupoboticario/flora-react";

export const MenuIconButton = styled(Box, {
  backgroundColor: "transparent",
  borderWidth: 0,
  color: "$nonInteractiveAltPredominant",
  cursor: "pointer",
  outline: "none",
  display: "flex",
  padding: "$2 $0",
  alignItems: "center",
  "> div": {
    pointerEvents: "none",
  },
});
