import { authService } from "@vdi/auth-service";

type NotificationItem = {
  id: string;
};

export const mutateNotificationItem = async ({
  id,
}: NotificationItem): Promise<void> => {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/v1/notifications/${id}`;

  const resp = await fetch(url, {
    body: JSON.stringify({ read: true }),
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
    },
  });

  if (!resp.ok) {
    throw new Error("Network response was not ok");
  }
};
